//
// Pagination
// --------------------------------------------------


// Pagination

.pagination {
  user-select: none;
}
.page-link {
  position: relative;
  transition: $pagination-transition;
  @include border-radius($btn-border-radius);
  font-weight: $pagination-font-weight;
  .feather {
    width: $pagination-icon-size;
    height: $pagination-icon-size;
    margin-top: -.15rem;
  }
  &.page-link-static:hover {
    border-color: transparent;
    background-color: $pagination-bg;
    color: $pagination-color;
  }
}
.page-item {
  margin: .15rem;
  &.active {
    position: relative;
    z-index: 5;
    cursor: default;
  }
}


// Entry navigation

.entry-navigation {
  display: flex;
  border: $entry-navigation-border-width solid $entry-navigation-border-color;
}
.entry-navigation-link {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding: $entry-navigation-padding-y $entry-navigation-padding-x;
  transition: $entry-navigation-transition;
  border-right: $entry-navigation-border-width solid $entry-navigation-border-color;
  color: $entry-navigation-color;
  font-weight: $entry-navigation-font-weight;
  text: {
    align: center;
    decoration: none;
  }
  .feather {
    width: $entry-navigation-icon-size;
    height: $entry-navigation-icon-size;
    margin-top: -.15rem;
  }
  &:last-child {
    border-right: 0;
  }
  &:hover {
    background-color: $entry-navigation-hover-bg;
    color: $entry-navigation-hover-color;
    text-decoration: none;
  }
}
