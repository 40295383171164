//
// Grid
// --------------------------------------------------

// Override default Bootstrap .container/.container-fluid paddings for large screens
@include media-breakpoint-up(lg) {
  .container:not(.navbar-inner),
  .container-fluid:not(.navbar-inner) {
    padding: {
      right: $grid-gutter-width;
      left: $grid-gutter-width;
    }
  }
}