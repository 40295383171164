//
// Typography
// --------------------------------------------------


// Responsive Display headings

@include media-breakpoint-down(md) {
  .display-1 { font-size: ($display1-size * .85); }
  .display-2 { font-size: ($display2-size * .86); }
  .display-3 { font-size: ($display3-size * .88); }
  .display-4 { font-size: ($display4-size * .9); }
}


// Blockquotes

.blockquote {
  position: relative;
  margin-top: $blockquote-icon-box-size / 2;
  padding: $blockquote-padding;
  border: $blockquote-border-width solid $blockquote-border-color;
  @include border-radius($blockquote-border-radius);
  > *:not(.blockquote-footer) {
    font-weight: $blockquote-font-weight;
  }
  > :first-child {
    display: block;
    padding-top: $spacer / 1.2;
  }
  &::before {
    display: block;
    position: absolute;
    top: -($blockquote-icon-box-size / 2);
    left: $blockquote-padding;
    width: $blockquote-icon-box-size;
    height: $blockquote-icon-box-size;
    border-radius: 50%;
    background-color: $blockquote-icon-box-bg;
    color: $blockquote-icon-color;
    font: {
      size: $blockquote-icon-font-size;
    }
    text-align: center;
    line-height: $blockquote-icon-font-size * 1.2;
    content: $blockquote-icon;
    box-shadow: $blockquote-icon-box-shadow;
  }
  &.text-center::before {
    left: 50%;
    margin-left: -($blockquote-icon-box-size / 2);
  }
  &.text-right::before {
    right: $blockquote-padding;
    left: auto;
  }
  &.comment {
    padding-top: $blockquote-padding / 2;
    font-size: $font-size-base;
    &::before { display: none; }
  }
}
.blockquote-footer {
  margin-top: $spacer;
  cite {
    font-style: normal;
  }
}


// Description list

dt {
  color: $headings-color;
  margin-bottom: $spacer / 4;
}
dd {
  font-size: $font-size-sm;
  margin-bottom: $spacer;
}