//
// Docs
// --------------------------------------------------

.docs-side-menu {
  border: {
    right: $border-width solid $border-color;
    left: $border-width solid $border-color;
    bottom: $border-width solid $border-color;
  }
  &::-webkit-scrollbar {
    width: .25rem;
    background-color: $gray-100;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $gray-400;
  }
  .docs-side-menu-inner {
    padding: {
      top: 1.5rem;
      bottom: 1.5rem;
    }
  }
  .list-group {
    margin-left: -$border-width;
    .list-group-item {
      padding: {
        right: .75rem;
        left: .85rem;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    position: fixed;
    width: 230px;
    height: 100%;
    border: {
      right: 0;
      bottom: 0;
    }
    overflow-y: auto;
    .docs-side-menu-inner {
      padding: {
        top: 2.2rem;
        bottom: ($input-btn-padding-y * 2) + ($font-size-base * $line-height-base) + 4rem;
      }
    }
    &.collapse { display: block; }
  }
}
