//
// Icon box
// --------------------------------------------------


// Icon

.icon-box-icon {
  display: inline-block;
  flex-shrink: 0;
  width: $icon-box-icon-box-size;
  height: $icon-box-icon-box-size;
  margin-bottom: $spacer * 1.5;
  background-color: $icon-box-icon-bg;
  border-radius: 50%;
  color: $icon-box-icon-color;
  text-align: center;
  line-height: $icon-box-icon-box-size - .25rem;
  i {
    font-size: $icon-box-icon-size;
    line-height: 1;
  }
  .feather {
    width: $icon-box-icon-size;
    height: $icon-box-icon-size;
  }
  img {
    display: block;
  }
}


// Title

.icon-box-title {
  margin-bottom: $spacer * .5;
  font-size: $icon-box-title-font-size;
}


// Text

.icon-box-text {
  margin-bottom: 0;
  color: $icon-box-text-color;
  font-size: $icon-box-text-font-size;
}
