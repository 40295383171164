//
// Social buttons
// --------------------------------------------------


// Base class

.social-btn {
  display: inline-block;
  width: $social-btn-size;
  height: $social-btn-size;
  transition: $social-btn-transition;
  border: $social-btn-border-width solid $social-btn-border-color;
  border-radius: 50%;
  background-color: $social-btn-bg;
  color: $social-btn-icon-color;
  text: {
    align: center;
    decoration: none;
  }
  line-height: $social-btn-size - .125rem;
  vertical-align: middle;
  > i::before {
    font-size: $social-btn-icon-size;
  }
  &:hover {
    text-decoration: none;
  }
}


// Small buttons to use inside header

.sb-sm {
  width: $social-btn-sm-size;
  height: $social-btn-sm-size;
  line-height: $social-btn-sm-size - .1875rem;
}


// Individual buttons

.sb-behance {
  > i::before { font-size: $social-btn-icon-size * 1.15; }
  @include social-btn-hover(#1769ff);
}
.sb-blogger {
  > i::before { font-size: $social-btn-icon-size * .9; }
  @include social-btn-hover(#ff5722);
}
.sb-digg {
  > i::before { font-size: $social-btn-icon-size * 1.2; }
  @include social-btn-hover(#000);
}
.sb-dribbble {
  > i::before { font-size: $social-btn-icon-size * 1.1; }
  @include social-btn-hover(#ea4c89);
}
.sb-dropbox {
  @include social-btn-hover(#0061fe);
}
.sb-envato {
  @include social-btn-hover(#82b541);
}
.sb-facebook {
  @include social-btn-hover(#3b5998);
}
.sb-flickr {
  @include social-btn-hover(#0063dc);
}
.sb-foursquare {
  @include social-btn-hover(#ef4b78);
}
.sb-github {
  > i::before { font-size: $social-btn-icon-size * 1.1; }
  @include social-btn-hover(#4183c4);
}
.sb-google-drive {
  @include social-btn-hover($primary);
}
.sb-google-plus {
  > i::before { font-size: $social-btn-icon-size * 1.2; }
  @include social-btn-hover(#dd4b39);
}
.sb-hangouts {
  @include social-btn-hover(#0f9d58);
}
.sb-instagram {
  @include social-btn-hover(#405de6);
}
.sb-kickstarter {
  @include social-btn-hover(#2bde73);
}
.sb-lastfm {
  > i::before { font-size: $social-btn-icon-size * 1.1; }
  @include social-btn-hover(#d51007);
}
.sb-linkedin {
  @include social-btn-hover(#0077b5);
}
.sb-messenger {
  @include social-btn-hover(#0084ff);
}
.sb-myspace {
  @include social-btn-hover(#000);
}
.sb-picasa {
  > i::before { font-size: $social-btn-icon-size * 1.1; }
  @include social-btn-hover(#99499b);
}
.sb-pinterest {
  @include social-btn-hover(#bd081c);
}
.sb-reddit {
  @include social-btn-hover(#ff4500);
}
.sb-rss {
  @include social-btn-hover(#f26522);
}
.sb-skype {
  @include social-btn-hover(#00aff0);
}
.sb-snapchat {
  @include social-btn-hover(#d5d30d);
}
.sb-soundcloud {
  @include social-btn-hover(#ff8800);
}
.sb-spotify {
  @include social-btn-hover(#1db954);
}
.sb-stumbleupon {
  @include social-btn-hover(#eb4924);
}
.sb-stumbleupon {
  @include social-btn-hover(#eb4924);
}
.sb-tumblr {
  @include social-btn-hover(#35465c);
}
.sb-twitch {
  @include social-btn-hover(#6441a5);
}
.sb-twitter {
  @include social-btn-hover(#1da1f2);
}
.sb-vimeo {
  @include social-btn-hover(#1ab7ea);
}
.sb-vine {
  @include social-btn-hover(#00b488);
}
.sb-vk {
  > i::before { font-size: $social-btn-icon-size * 1.15; }
  @include social-btn-hover(#45668e);
}
.sb-whatsapp {
  @include social-btn-hover(#128c7e);
}
.sb-wordpress {
  > i::before { font-size: $social-btn-icon-size * 1.2; }
  @include social-btn-hover(#0087be);
}
.sb-xing {
  @include social-btn-hover(#026466);
}
.sb-yahoo {
  @include social-btn-hover(#410093);
}
.sb-youtube {
  @include social-btn-hover(#ff0000);
}


// Light variant

.sb-light {
  border-color: $social-btn-light-border-color;
  background-color: transparent;
  color: $social-btn-light-icon-color;
  &:hover {
    border-color: $social-btn-light-border-color;
    background-color: $social-btn-light-hover-bg;
    color: $social-btn-light-hover-icon-color;
  }
}


// PayPal icon

.icon-paypal {
  display: inline-block;
  width: 1.1rem;
  height: 1.1rem;
  background: {
    position: center;
    image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDEwMCAxMDAiIGhlaWdodD0iMTAwcHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB3aWR0aD0iMTAwcHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxnPjxkZWZzPjxyZWN0IGhlaWdodD0iMTAwIiBpZD0iU1ZHSURfMV8iIHdpZHRoPSIxMDAiLz48L2RlZnM+PHBhdGggZD0iTTYzLjA1Nyw1SDIzLjY0N0w2LjE4OCw4NS4xNDZoMjMuMTI0bDUuNjY1LTI2LjU1OGgxNi41MmMxNS44MDMsMCwyOS4wMi05Ljc0NCwzMi41NjItMjYuMzEyICAgQzg4LjA2MiwxMy41MjEsNzQuNjExLDUsNjMuMDU3LDUgTTQ1LjgxNCw0My4yNDJoLTcuNTM2bDQuOTUzLTIxLjkyNGgxMS4zMjdjMy44ODUsMCw2LjgyNiwyLjMxNCw3Ljc5Myw1LjcxMiAgIGMtMC40OTYtMC4wODYtMC45NTUtMC4yMzUtMS40OTgtMC4yMzVINDkuNTI4TDQ1LjgxNCw0My4yNDJ6IE02Mi4zNDYsMzIuMjc4Yy0xLjM3OCw1Ljk1LTcuMDMzLDEwLjY5OC0xMi43ODksMTAuOTIxbDIuNzExLTEyLjAyMyAgIGgxMC4yNjZDNjIuNDczLDMxLjU0Myw2Mi40NDcsMzEuODk3LDYyLjM0NiwzMi4yNzggTTkwLjM1NCwzNy43NTRjMS4zMjYtNi4yMTEsMC43MTEtMTEuMjc1LTEuMDg2LTE1LjMwNiAgIGMzLjc0OCw0LjQ3LDUuNjg4LDEwLjk1NSwzLjgyNSwxOS42ODdDODkuNTUsNTguNzAxLDc2LjMzMSw2OC40NDUsNjAuNTMxLDY4LjQ0NWgtMTYuNTJMMzguMzQ5LDk1SDE1LjIyM2wwLjk2LTQuMzgxaDE5LjQyNyAgIGw1LjY2My0yNi41NTVoMTYuNTIxQzczLjU5Niw2NC4wNjQsODYuODEyLDU0LjMyLDkwLjM1NCwzNy43NTQiLz48L2c+PC9zdmc+);
    size: cover;
  }
  vertical-align: middle;
}
