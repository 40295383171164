//
// Page Title
// --------------------------------------------------

.page-title-wrapper {
  padding: $page-title-padding-y 0;
}
.container,
.container-fluid {
  .page-title-wrapper .container,
  .page-title-wrapper .container-fluid {
    padding: {
      right: 0;
      left: 0;
    }
  }
}

.page-title {
  margin-bottom: 0;
  font-size: $page-title-font-size;
}
@include media-breakpoint-down(md) {
  .page-title-wrapper {
    padding: ($page-title-padding-y / 1.4) 0;
  }
  .page-title {
    font-size: ($page-title-font-size * .8);
  }
}
