//
// Video Player
// --------------------------------------------------

.video-player-btn,
.video-player-label {
  display: inline-block;
  vertical-align: middle;
}
.video-player-btn {
  width: $video-player-btn-size;
  height: $video-player-btn-size;
  padding-left: .15rem;
  transition: all .3s;
  border-radius: 50%;
  background-color: $video-player-btn-bg;
  color: $video-player-btn-color;
  line-height: $video-player-btn-size - .25rem;
  box-shadow: $video-btn-box-shadow;
  .feather {
    width: $video-player-btn-icon-size;
    height: $video-player-btn-icon-size;
  }
  text: {
    align: center;
    decoration: none;
  }
  &:hover {
    color: $primary;
    box-shadow: $video-btn-hover-box-shadow;
  }
}

// Video cover
.video-cover {
  position: relative;
  .badge {
    position: absolute;
    right: .5rem;
    bottom: .5rem;
    z-index: 5;
  }
}
